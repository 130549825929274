import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

import InternalLink from './Link';

import Logo from 'assets/svgs/logo.svg';
import { BREAKPOINTS, WEB_SIDE_PADDING, MOBILE_SIDE_PADDING, ROUTES } from 'utils/constants';

interface IContainer {
  isScrollMenu: boolean;
}

interface IHeader {
  isVisible: boolean;
  isStartNowButtonVisible?: boolean;
  logoLink?: ROUTES;
}

const Container = styled.div<IContainer>((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  position: 'fixed',
  alignItems: 'center',
  padding: `32px ${WEB_SIDE_PADDING}`,
  top: 0,
  left: 0,
  right: 0,
  backgroundColor: `rgba(6, 18, 24, 0)`,
  zIndex: 100,
  transition: 'all 0.3s ease-out',
  backdropFilter: 'blur(0)',
  [BREAKPOINTS[800]]: {
    paddingLeft: MOBILE_SIDE_PADDING,
    paddingRight: MOBILE_SIDE_PADDING,
  },
  ...(props.isScrollMenu && {
    backgroundColor: `rgba(6, 18, 24, 0.9)`,
    backdropFilter: 'blur(20px)',
    paddingTop: 8,
    paddingBottom: 8,
    transition: 'all 0.5s ease-out',
  }),
}));

const LogoContainer = styled(Link)({
  textDecoration: 'none',
});

const StyledLogo = styled(Logo)({
  cursor: 'pointer',
  [BREAKPOINTS[800]]: {
    height: 40,
  },
});

const Header: React.FC<IHeader> = ({
  isVisible,
  isStartNowButtonVisible = false,
  logoLink = ROUTES.MAIN,
}) => {
  const [isScrollMenu, setScrollMenu] = useState<boolean>(false);

  const scrollListener = () => {
    const scrollNumber = window.scrollY;

    if (scrollNumber > 15) {
      setScrollMenu(true);
    } else {
      setScrollMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', scrollListener);

    return () => {
      document.removeEventListener('scroll', scrollListener);
    };
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <Container isScrollMenu={isScrollMenu}>
      <LogoContainer to={logoLink}>
        <StyledLogo />
      </LogoContainer>
      {isStartNowButtonVisible && <InternalLink to={ROUTES.CHECKOUT}>Start now</InternalLink>}
    </Container>
  );
};

export default Header;
