import React from 'react';
import { navigate } from 'gatsby';
import { animateScroll } from 'react-scroll';
import styled from '@emotion/styled';

import { ROUTES } from 'utils/constants';

interface ILink {
  onClick?: () => void;
  to?: ROUTES;
  scrollToBottom?: boolean;
  children: React.ReactNode;
}

const StyledLink = styled.button((props) => ({
  backgroundColor: props.theme.colors.orange,
  color: 'rgba(255, 255, 255, 0.9)',
  fontSize: 18,
  letterSpacing: '1px',
  lineHeight: '27px',
  fontWeight: 400,
  textDecoration: 'none',
  padding: '8px 24px',
  borderWidth: 0,
  borderRadius: 100,
  textAlign: 'center',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: props.theme.colors.buttonOrangeHover,
  },
}));

const Link: React.FC<ILink> = ({ onClick, to, scrollToBottom = false, children, ...rest }) => {
  const handleClick = () => {
    if (scrollToBottom) {
      animateScroll.scrollToBottom();

      return;
    }

    if (onClick) {
      onClick();

      return;
    }

    if (to) {
      navigate(to);
    }
  };

  return (
    <StyledLink onClick={handleClick} {...rest}>
      {children}
    </StyledLink>
  );
};

export default Link;
